// extracted by mini-css-extract-plugin
export var borderBottom = "CardCarousel-module--borderBottom--Dg8YQ";
export var button = "CardCarousel-module--button--QWydT";
export var buttonGroup = "CardCarousel-module--buttonGroup--+vgHS";
export var buttonIcon = "CardCarousel-module--buttonIcon--k5HUs";
export var buttonLeft = "CardCarousel-module--buttonLeft--E7coa";
export var buttonRight = "CardCarousel-module--buttonRight--gGroR";
export var buttonWrapper = "CardCarousel-module--buttonWrapper--WuR+f";
export var card = "CardCarousel-module--card--K2flM";
export var cardContainer = "CardCarousel-module--cardContainer--cZVlD";
export var carousel = "CardCarousel-module--carousel--TB7ev";
export var companyContainer = "CardCarousel-module--companyContainer--24tcq";
export var container = "CardCarousel-module--container--AD8LK";
export var fakeBorderBottom = "CardCarousel-module--fakeBorderBottom--XGHHX";
export var fieldAndLocation = "CardCarousel-module--fieldAndLocation--kPInr";
export var galleryButton = "CardCarousel-module--galleryButton--38-0b";
export var image = "CardCarousel-module--image--JRpW4";
export var imageContainer = "CardCarousel-module--imageContainer--AJn0w";
export var leftButton = "CardCarousel-module--leftButton--zJTM7";
export var name = "CardCarousel-module--name--ATMau";
export var projectImages = "CardCarousel-module--projectImages--W+c5F";
export var projectsBlockContainer = "CardCarousel-module--projectsBlockContainer--K0+a0";
export var rightButton = "CardCarousel-module--rightButton--GA6mu";
export var text = "CardCarousel-module--text--Y4ixx";