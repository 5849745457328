import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { DEFAULT_LANG, SITE_BASE_URL } from '../constants';
import { Seo } from '../fragments';
import SEO from './SEO';

interface QueryData {
  sanityWebsite: {
    seo: Seo;
  };
}

interface PageSEOProps {
  defaultTitle?: string;
  seo: {
    title: string;
    description?: string;
    image?: {
      asset: {
        url: string;
      };
    };
  };
}

function PageSEO({ defaultTitle, seo }: PageSEOProps): React.ReactElement {
  const websiteSeoData: QueryData = useStaticQuery(graphql`
    {
      sanityWebsite {
        seo {
          ...SeoWebsite
        }
      }
    }
  `);
  const location = useLocation();
  const {
    sanityWebsite: { seo: websiteSEO },
  } = websiteSeoData;
  return (
    <SEO
      title={seo?.title || defaultTitle ? seo?.title || defaultTitle : websiteSEO?.title}
      description={seo?.description || websiteSEO?.description}
      url={`${SITE_BASE_URL}${location.pathname}`}
      imageUrl={(seo?.image || websiteSEO?.image).asset.url}
      lang={DEFAULT_LANG}
    />
  );
}

export default PageSEO;
