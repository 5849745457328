import React from 'react';
import { useTranslation } from 'react-i18next';

import '../fragments.ts';

import * as styles from './BackgroundWord.module.scss';

interface BackgroundWordProps {
  backgroundWord: string;
  className?: string;
}

const BackgroundWord = ({ backgroundWord, className }: BackgroundWordProps): React.ReactElement => {
  const { i18n, t } = useTranslation();

  return <span className={styles.backgroundWord + ' ' + className}>{backgroundWord}</span>;
};

export default BackgroundWord;
