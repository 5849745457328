import React from 'react';
import { clsx } from '../utils/utils';

import * as styles from './Header.module.scss';
import Menu from './Menu';
import MenuButton from './MenuButton';

interface HeaderProps {
  isMenuOpen: boolean;
  onHamburguerMenuTouch: () => void;
}

const Header = ({ isMenuOpen, onHamburguerMenuTouch }: HeaderProps): React.ReactElement => {
  const entries = [
    {
      url: '#hero',
      title: 'Início',
    },
    {
      url: '#about',
      title: 'Sobre Nós',
    },
    {
      url: '#photoshoots',
      title: 'Reportagens Fotográficas',
    },
    {
      url: '#projects',
      title: 'Lista de Projectos',
    },
    {
      url: '#partners',
      title: 'Parceiros',
    },
    {
      url: '#ourSpace',
      title: 'O Nosso Espaço',
    },
    {
      url: '#contacts',
      title: 'Contactos',
    },
  ];

  return (
    <header className={clsx(styles.header, 'header')}>
      <MenuButton isMenuOpen={isMenuOpen} onTouch={onHamburguerMenuTouch}></MenuButton>
      <div className={styles.menuWrapper + (isMenuOpen ? ' ' + styles.menuOpen : '')}>
        <Menu onTouch={onHamburguerMenuTouch} entries={entries}></Menu>
      </div>
    </header>
  );
};

export default Header;
