import React from 'react';

import '../fragments.ts';

import * as styles from './HighlightedPhrase.module.scss';

interface HighlightedPhraseProps {
  highlightedPhrase: React.ReactNode;
  className?: string;
}

const HighlightedPhrase = ({
  highlightedPhrase,
  className,
}: HighlightedPhraseProps): React.ReactElement => {
  return <h1 className={styles.phrase + ' ' + className}>{highlightedPhrase}</h1>;
};

export default HighlightedPhrase;
