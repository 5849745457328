import React from 'react';
import Select from 'react-select';
import { FaSpinner, FaCheck } from 'react-icons/fa';
import { useFormField, useForm } from '../utils/forms';
import InputField from './InputField';

import '../styles/main.scss';
import * as styles from './Form.module.scss';
import { clsx } from '../utils/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function encodeFormData(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

function wait(ms: number) {
  return new Promise(function (resolve, reject) {
    setTimeout(resolve, ms);
  });
}

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#eeeeee' : 'white',
    cursor: 'pointer',
    color: state.isSelected ? '#434343' : '#4d4d4d',
    padding: 20,
    fontSize: '16px',
    fontFamily: 'Raleway, sans-serif;',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Raleway, sans-serif;',
    fontSize: '16px',
    color: '#434343',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    fontFamily: 'Raleway, sans-serif;',
    color: '#888888',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#dedede',
    boxShadow: '0 0 0 0 transparent',
    borderColor: state.isFocused ? '#555555' : 'transparent',
    '&:hover': {
      borderColor: '#555555',
    },
    opacity: state.isDisabled ? '0.8' : '1',
  }),
};

const Form = (): React.ReactElement => {
  const fieldsByName = {
    name: useFormField<string>('', ['required']),
    phone: useFormField<string>('', ['required']),
    email: useFormField<string>('', ['required', 'email']),
    topic: useFormField<string>('', ['required']),
    message: useFormField<string>('', []),
  } as const;

  async function onSubmit() {
    // Code to test form states (can be deleted)
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success

    const resp = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeFormData({
        'form-name': 'contact',
        ...{
          name: fieldsByName.name.value,
          phone: fieldsByName.phone.value,
          email: fieldsByName.email.value,
          topic: fieldsByName.topic.value,
          message: fieldsByName.message.value,
        },
      }),
    });
    if (resp.status !== 200) {
      throw new Error('Got response with status code ' + resp.status);
    }
    return true;
  }

  const { getFieldProps, renderSubmitButton, renderFormMessage, submitState } = useForm({
    fieldsByName,
    onSubmit,
    translateFunction: key => {
      return {
        'form.required_field_error': 'Este campo é obrigatório',
        'form.invalid_email_error': 'Email inválido (e.g. email@example.com)',
        'form.network_error': 'A rede falhou ao enviar o seu pedido, por favor tente novamente.',
        'form.unknown_error': 'Ocorreu um erro desconhecido, por favor tente novamente.',
        'form.success_message': 'Obrigado pela sua mensagem, entraremos em contacto em breve.',
      }[key];
    },
  });

  const topicSelectOptions = [
    { value: 'catalogRequest', label: 'Pedido de catálogo' },
    { value: 'quotationRequest', label: 'Pedido de quotação' },
    { value: 'projectQuotationRequest', label: 'Pedido de quotação de projecto' },
    { value: 'other', label: 'Outro' },
  ];

  return (
    <div className={styles.formContainer}>
      <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
        <input
          className={styles.hiddenField}
          name="hidden-field"
          placeholder="language"
          autoComplete="language"
          type="text"
        />
        <div className={styles.inputContainer}>
          <div className={styles.nameAndPhone}>
            <div className={styles.nameInput}>
              <label className={styles.label} htmlFor="name-input">
                Nome*
              </label>
              <InputField
                className={styles.input + ' ' + styles.nameInput}
                type="text"
                id="name-input"
                name="name"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.name)}
              />
            </div>
            <div className={styles.phoneInput}>
              <label className={styles.label} htmlFor="phone-input">
                Telefone*
              </label>
              <InputField
                className={styles.input + ' ' + styles.phoneInput}
                type="phone"
                id="phone-input"
                name="phone"
                helperTextClass={styles.helperText}
                {...getFieldProps(fieldsByName.phone)}
              />
            </div>
          </div>
          <label className={styles.label} htmlFor="email-input">
            Email*
          </label>
          <InputField
            className={styles.input}
            type="email"
            id="email-input"
            name="email"
            helperTextClass={styles.helperText}
            {...getFieldProps(fieldsByName.email)}
          />
          <label className={styles.label} htmlFor="purpose-input">
            Motivo de contacto*
          </label>

          <div className={styles.selectWrapper}>
            <Select
              name="topic"
              placeholder="Escolha..."
              styles={customStyles}
              options={topicSelectOptions}
              value={
                topicSelectOptions.find(option => option.value === fieldsByName.topic.value) ||
                undefined
              }
              onChange={currentOption => {
                fieldsByName.topic.setValue(currentOption ? currentOption.value : '');
                fieldsByName.topic.setError('');
              }}
              // onBlur={() => onFieldUnfocus(fieldsByName.topic)}
              isSearchable={false}
              isDisabled={submitState === 'submitted'}
            />
            <div
              className={!!fieldsByName.topic.error ? styles.helperText : ''}
              style={{ visibility: !fieldsByName.topic.error ? 'hidden' : undefined }}
            >
              {fieldsByName.topic.error || 'x'}
            </div>
          </div>
          <label className={styles.label} htmlFor="message-input">
            Mensagem
          </label>
          <InputField
            className={styles.input + ' ' + styles.messageInput}
            textarea
            id="message-input"
            name="message"
            helperTextClass={styles.helperText}
            {...getFieldProps(fieldsByName.message)}
          />
        </div>
        {renderSubmitButton({
          labels: {
            ready: 'Enviar',
            submitting: 'A enviar',
            submitted: 'Enviado',
          },
          btnClasses: {
            common: styles.submitButton,
            ready: styles.formReady,
            submitting: styles.formSubmitting,
            submitted: styles.formSubmitted,
          },
          childrenBefore:
            (submitState === 'submitting' && (
              <FaSpinner className={clsx(styles.buttonIcon, styles.buttonIconLoading)}></FaSpinner>
            )) ||
            (submitState === 'submitted' && (
              <FaCheck className={clsx(styles.buttonIcon, styles.buttonIconSubmitted)}></FaCheck>
            )),
        })}
        {renderFormMessage({
          styles: {
            formMessage: styles.formMessage,
            formMessageSuccess: styles.formMessageSuccess,
            formMessageError: styles.formMessageError,
          },
        })}
      </form>
    </div>
  );
};

export default Form;
