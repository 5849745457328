import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { replaceNewLinesWithBr } from '../../utils/sanity';
import '../../fragments.ts';

import * as styles from './OurSpaceSection.module.scss';
import BackgroundWord from '../BackgroundWord';

export const OurSpaceSectionFragment = graphql`
  fragment OurSpaceSection on SanityOurSpaceSection {
    title
    text
    backgroundWord
    images {
      asset {
        url
      }
    }
  }
`;

export type OurSpaceSectionProps = {
  title: string;
  text: string;
  backgroundWord: string;
  images: Array<{
    asset: {
      url: string;
    };
  }>;
};

const OurSpaceSection = ({
  title,
  text,
  backgroundWord,
  images,
}: OurSpaceSectionProps): React.ReactElement => {
  return (
    <section id="ourSpace" className={styles.section}>
      <div className={styles.contentContainer}>
        <div className={styles.imagesContainerWrapper}>
          <div className={styles.imagesContainer}>
            {images.map((image, i) => (
              <div
                className={styles.imageWrapper}
                key={i}
                data-aos="fade-up"
                data-aos-delay={i * 100}
                data-aos-offset="300"
                data-aos-duration="1000"
              >
                <img
                  className={styles.image}
                  src={image.asset.url + '?w=560&h=860&fit=min'}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.textContainer}>
          <h2>{title}</h2>
          <p className={styles.mainText}>{replaceNewLinesWithBr(text)}</p>
          <BackgroundWord backgroundWord={backgroundWord} className={styles.bgWord} />
        </div>
      </div>
    </section>
  );
};

export default OurSpaceSection;
