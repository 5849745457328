import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import * as styles from './Accordion.module.scss';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  startsOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const Accordion = ({
  title,
  children,
  startsOpen,
  onOpen,
  onClose,
}: AccordionProps): React.ReactElement => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(!!startsOpen);
  return (
    <section className={styles.accordion + (isAccordionOpen ? ' ' + styles.accordionOpen : '')}>
      <div className={styles.accordionBox}>
        <div
          className={styles.titleBox}
          onClick={() => {
            setIsAccordionOpen(!isAccordionOpen);
            if (isAccordionOpen) {
              if (onClose) {
                onClose();
              }
            } else {
              if (onOpen) {
                onOpen();
              }
            }
          }}
        >
          <span className={styles.title}>{title}</span>
          <FaChevronDown className={styles.icon}></FaChevronDown>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </section>
  );
};

export default Accordion;
