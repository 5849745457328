import React from 'react';
import { graphql } from 'gatsby';
import '../../fragments.ts';

import * as styles from './PhotoReportsSection.module.scss';
import BackgroundWord from '../BackgroundWord';
import HighlightedPhrase from '../HighlightedPhrase';
import rightSideLight from '../../images/rightSideLight.svg';
import CardCarousel from '../../components/CardCarousel';
import Divider from './Divider';
import { Project } from '../../fragments';

export const PhotoReportsSectionFragment = graphql`
  fragment PhotoReportsSection on SanityPhotoReportsSection {
    highlightedPhrase
    backgroundWord
    title
    projects {
      ...Project
    }
  }
`;

export type PhotoReportsSectionProps = {
  highlightedPhrase: string;
  backgroundWord: string;
  title: string;
  projects: Array<Project>;
  onViewGalleryClick: (projectId: string) => void;
};

const PhotoReportsSection = ({
  highlightedPhrase,
  backgroundWord,
  title,
  projects,
  onViewGalleryClick,
}: PhotoReportsSectionProps): React.ReactElement => {
  return (
    <section id="photoshoots" className={styles.photoshootSection}>
      <img className={styles.rightSideLight} src={rightSideLight} alt="" />
      <div className={styles.contentContainer}>
        <h2>{title}</h2>
        <div className={styles.carouselContainer}>
          <CardCarousel
            className={styles.cardCarousel}
            projects={projects}
            onViewGalleryClick={onViewGalleryClick}
          ></CardCarousel>
        </div>
      </div>
      <div className={styles.backgroundWordContainer}>
        <BackgroundWord backgroundWord={backgroundWord} className={styles.backgroundWord} />
        <Divider className={styles.divider} />
        <div className={styles.dividerLight}></div>
        <div data-aos="fade-left" data-aos-delay="0" data-aos-offset="300" data-aos-duration="1000">
          <HighlightedPhrase
            highlightedPhrase={highlightedPhrase}
            className={styles.highlightedPhrase}
          />
        </div>
      </div>
    </section>
  );
};

export default PhotoReportsSection;
