import React, { useLayoutEffect, useRef } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuSpy from 'menuspy';

import * as styles from './Menu.module.scss';

interface MenuProps {
  entries: Array<{
    url: string;
    title: string;
  }>;

  onTouch: () => void;
}

const Menu = ({ entries, onTouch }: MenuProps): React.ReactElement => {
  const menuSpyRef = useRef<any>(null);

  useLayoutEffect(() => {
    menuSpyRef.current = new MenuSpy(document.querySelector(`.header`), {
      menuItemSelector: `.${styles.link}`,
      activeClass: styles.active,
      // threshold: 300,
    });
    // HACK: Force menuSpy to run at least once, so that the first entry gets selected
    menuSpyRef.current.tick();

    return () => {
      if (menuSpyRef.current) {
        menuSpyRef.current.destroy();
      }
    };
  }, []);
  return (
    <nav>
      <ul className={styles.menuContainer + ' ' + styles.subMenuEntriesWrapper}>
        {entries.map((entry, i) => (
          <li key={i} className={styles.linkWrapper}>
            <AnchorLink onClick={onTouch} href={entry.url} className={styles.link}>
              {entry.title}
            </AnchorLink>
            <div className={styles.borderBottom}></div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
