import React from 'react';
import { graphql } from 'gatsby';

import { replaceNewLinesWithBr } from '../../utils/sanity';
import '../../fragments.ts';

import * as styles from './AboutUsSection.module.scss';
import BackgroundWord from '../BackgroundWord';
import HighlightedPhrase from '../HighlightedPhrase';

export const AboutUsSectionFragment = graphql`
  fragment AboutUsSection on SanityAboutUsSection {
    highlightedPhrase
    backgroundWord
    text
  }
`;

export type AboutUsSectionProps = {
  highlightedPhrase: string;
  backgroundWord: string;
  text: string;
};

const AboutUsSection = ({
  highlightedPhrase,
  backgroundWord,
  text,
}: AboutUsSectionProps): React.ReactElement => {
  return (
    <section id="about" className={styles.contentContainer}>
      <div className={styles.leftSideLight}></div>
      <div className={styles.aboutUsContainer}>
        <BackgroundWord backgroundWord={backgroundWord} className={styles.backgroundWord} />
        <div
          className={styles.textContainer}
          data-aos="fade-left"
          data-aos-delay="0"
          data-aos-offset="300"
          data-aos-duration="1000"
        >
          <HighlightedPhrase highlightedPhrase={highlightedPhrase} />
          <p className={styles.mainText}>{replaceNewLinesWithBr(text)}</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
