import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { replaceNewLinesWithBr } from '../../utils/sanity';
import '../../fragments.ts';

import * as styles from './PartnersSection.module.scss';
import HighlightedPhrase from '../HighlightedPhrase';
import lamp from '../../images/lamp-01.png';
import lampOn from '../../images/lampOn.png';

export const PartnersSectionFragment = graphql`
  fragment PartnersSection on SanityPartnersSection {
    title
    text
    highlightedPhrase
    partners {
      name
      url
      image {
        asset {
          url
        }
      }
    }
  }
`;

export type PartnersSectionProps = {
  title: string;
  text: string;
  highlightedPhrase: string;
  partners: Array<{
    name: string;
    url: string;
    image: {
      asset: {
        url: string;
      };
    };
  }>;
};

const PartnersSection = ({
  title,
  text,
  highlightedPhrase,
  partners,
}: PartnersSectionProps): React.ReactElement => {
  const aosOffsetMultiplier = Math.min(
    Math.max(typeof window !== 'undefined' ? (window.innerHeight - 600) / 400 : 1, 0),
    1,
  );
  const lampLight = useRef<HTMLDivElement | null>(null);
  return (
    <section id="partners" className={styles.contentContainer}>
      <div
        onClick={() => {
          if (lampLight.current) {
            if (lampLight.current.classList.contains('aos-animate')) {
              lampLight.current.classList.remove('aos-animate');
            } else {
              lampLight.current.classList.add('aos-animate');
            }
          }
        }}
        className={styles.lampAndLightContainer}
      >
        <div className={styles.lampContainer}>
          <img className={styles.lamp} src={lamp} alt="" />
        </div>
        <div
          ref={lampLight}
          className={styles.light}
          data-aos="llFadeInLight"
          data-aos-delay="0"
          data-aos-offset={300 + 200 * aosOffsetMultiplier}
          data-aos-duration="400"
        ></div>
      </div>
      <div className={styles.partnersContainer}>
        <div className={styles.textContainer}>
          <h2>{title}</h2>
          <p className={styles.mainText}>{replaceNewLinesWithBr(text)}</p>
          <HighlightedPhrase highlightedPhrase={replaceNewLinesWithBr(highlightedPhrase)} />
        </div>
        <div className={styles.partnersLogosContainer}>
          {partners.map((partner, i) => (
            <a key={i} href={partner.url} target="_blank" rel="noreferrer">
              <img src={partner.image.asset.url + '?w=240&h=160&fit=max'} alt={partner.name} />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
