import React from 'react';

import '../fragments.ts';

import * as styles from './ProjectFieldRow.module.scss';
import Accordion from './Accordion';
import AOS from 'aos';

export type ProjectFieldRowProps = {
  title: string;
  projects: Array<{
    _id: string;
    name: string;
    location: string;
    photos: Array<{
      asset: {
        url: string;
      };
    }>;
  }>;
  onViewGalleryClick: (projectId: string) => void;
};

const ProjectFieldRow = ({
  title,
  projects,
  onViewGalleryClick,
}: ProjectFieldRowProps): React.ReactElement => {
  return (
    <Accordion
      title={title}
      onOpen={() => {
        setTimeout(() => {
          AOS.refresh();
        }, 200);
      }}
      onClose={() => {
        setTimeout(() => {
          AOS.refresh();
        }, 200);
      }}
    >
      {projects.map(project =>
        project.photos.length >= 1 ? (
          <button
            className={styles.project + ' ' + styles.projectWithGallery}
            key={project._id}
            onClick={e => {
              e.preventDefault();
              onViewGalleryClick(project._id);
            }}
          >
            <span className={styles.nameAndLocation}>
              {project.name} - {project.location}
            </span>
            <span className={styles.viewGallery}>Ver galeria</span>
          </button>
        ) : (
          <div className={styles.project} key={project._id}>
            <span className={styles.nameAndLocation}>
              {project.name} - {project.location}
            </span>
          </div>
        ),
      )}
    </Accordion>
  );
};

export default ProjectFieldRow;
