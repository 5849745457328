// extracted by mini-css-extract-plugin
export var becomesLogo = "Hero-module--becomesLogo--69ceJ";
export var bottomLogoContainer = "Hero-module--bottomLogoContainer--3RGVZ";
export var fadeIn = "Hero-module--fadeIn--iR-GP";
export var finalLogo = "Hero-module--finalLogo--VFTrU";
export var heroContainer = "Hero-module--heroContainer--BrZBw";
export var initialLogo = "Hero-module--initialLogo--7yhCr";
export var lightLogo = "Hero-module--lightLogo--aTdT0";
export var logo = "Hero-module--logo--oueod";
export var logoContainer = "Hero-module--logoContainer--IrN1o";
export var mainContent = "Hero-module--mainContent--yb+fX";
export var mouse = "Hero-module--mouse--p9Oyp";
export var rotate = "Hero-module--rotate--QbBTx";
export var scroll = "Hero-module--scroll--4-JCq";
export var scrollContainer = "Hero-module--scrollContainer--xiESl";
export var scrollText = "Hero-module--scrollText--UJaZT";
export var spaceLogo = "Hero-module--spaceLogo--1k6pD";
export var topLogoContainer = "Hero-module--topLogoContainer--9tV8w";