import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import Carousel from 'react-multi-carousel';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import 'react-multi-carousel/lib/styles.css';
import '../styles/libraryStyles.css';

// import { GatsbySanityImageFluid } from '../types';

import * as styles from './CardCarousel.module.scss';

interface CardCarouselProps {
  className: string;
  projects: Array<{
    _id: string;
    field: {
      name: string;
    };
    name: string;
    location: string;
    responsibleCompanies: Array<{
      name: string;
      area: string;
    }>;
    photos: Array<{
      asset: {
        url: string;
      };
    }>;
  }>;

  onViewGalleryClick: (projectId: string) => void;
}

const responsive = {
  // the name can be any, depends on you.
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 3,
  },
  laptop: {
    breakpoint: { max: 960, min: 0 },
    items: 2,
    partialVisibilityGutter: 15,
  },
  tablet: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

const ButtonGroup = ({
  next,
  previous,
  carouselState: { currentSlide, totalItems, slidesToShow },
}: {
  next: () => void;
  previous: () => void;
  carouselState: { currentSlide: number; totalItems: number; slidesToShow: number };
}) => {
  return (
    // remember to give it position:absolute
    <div className={styles.buttonGroup}>
      <button
        disabled={currentSlide === 0}
        className={styles.button + ' ' + styles.buttonLeft}
        onClick={() => previous()}
      >
        {<FaArrowLeft className={styles.buttonIcon} />}
      </button>

      <button
        disabled={currentSlide === totalItems - slidesToShow}
        className={styles.button + ' ' + styles.buttonRight}
        onClick={() => next()}
      >
        {<FaArrowRight className={styles.buttonIcon} />}
      </button>
    </div>
  );
};

const CardCarousel = ({
  projects,
  className,
  onViewGalleryClick,
}: CardCarouselProps): React.ReactElement => {
  return (
    <div className={styles.container + ' ' + className}>
      <Carousel
        arrows={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        containerClass={styles.carousel}
        // @ts-ignore
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside
        partialVisible
        showDots={true}
      >
        {projects.map((project, i) => (
          <div
            className={styles.card}
            key={i}
            onClick={e => {
              e.preventDefault();
              onViewGalleryClick(project._id);
            }}
          >
            <div className={styles.imageContainer}>
              <img
                src={project.photos[0].asset.url + '?w=1000&h=660&fit=min'}
                alt=""
                className={styles.image}
              />
            </div>
            <div className={styles.fieldAndLocation}>
              {project.field.name + ' | ' + project.location}
            </div>

            <h3 className={styles.name}>{project.name}</h3>

            {project.responsibleCompanies.map((company, i) => (
              <div key={i} className={styles.companyContainer}>
                <span>{company.area}</span>
                <span className={styles.companyName}>{company.name}</span>
              </div>
            ))}
            <div
              className={styles.buttonWrapper}
              onClick={e => {
                e.preventDefault();
                onViewGalleryClick(project._id);
              }}
            >
              <button className={styles.galleryButton}>Ver galeria</button>
              <div className={styles.borderBottom}></div>
              <div className={styles.fakeBorderBottom}></div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CardCarousel;
