import React from 'react';

import * as styles from './Footer.module.scss';

const Footer = (): React.ReactElement => {
  return (
    <footer className={styles.footer}>
      <div className={styles.copyrightContainer}>
        <span className={styles.span}>Copyright LightLab</span>
        <span className={styles.divider}>|</span>
        <span className={styles.span}>
          Powered by{' '}
          <a
            className={styles.moscaLink}
            target="_blank"
            rel="noreferrer"
            href={'https://moscadigital.pt/?utm_source=lightlab.pt'}
          >
            Mosca Digital
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
