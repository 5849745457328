import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import lightlabSideLogo from '../images/lightlabSideLogo.svg';
import * as styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className={styles.container}>
      <Header
        isMenuOpen={isMenuOpen}
        onHamburguerMenuTouch={() => setIsMenuOpen(!isMenuOpen)}
      ></Header>
      <img className={styles.sideLogo} src={lightlabSideLogo}></img>
      <main className={styles.main}>{children}</main>
      <Footer></Footer>
    </div>
  );
};

export default Layout;
