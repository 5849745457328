import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '../constants';

interface WithI18nComponentProps {
  lang?: string;
}

function withI18n(
  WrappedComponent: React.ComponentType,
  defaultLang: 'pt' | 'en' = DEFAULT_LANG,
): React.ComponentType<WithI18nComponentProps> {
  const WithI18n = ({ lang, ...rest }: WithI18nComponentProps): React.ReactElement => {
    const { i18n } = useTranslation();
    if (lang === undefined) {
      if (
        typeof window !== 'undefined' &&
        window.location &&
        window.location.pathname.startsWith('/en')
      ) {
        lang = 'en';
      } else {
        lang = defaultLang;
      }
    }
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
    return <WrappedComponent {...rest} />;
  };
  WithI18n.displayName = WrappedComponent.displayName;
  return WithI18n;
}

export default withI18n;
