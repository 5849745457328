import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import '../../fragments.ts';

import * as styles from './ProjectsSection.module.scss';
import ProjectFieldRow from '../ProjectFieldRow';
import { Project } from '../../fragments';

export const ProjectsSectionFragment = graphql`
  fragment ProjectsSection on SanityProjectsSection {
    title
  }
`;

export type ProjectsSectionProps = {
  title: string;
  onViewGalleryClick: (projectId: string) => void;
};

interface QueryData {
  allSanityProjectField: {
    nodes: Array<{
      name: string;
      id: string;
    }>;
  };
  allSanityProject: {
    nodes: Array<Project>;
  };
}

const ProjectsSection = ({
  title,
  onViewGalleryClick,
}: ProjectsSectionProps): React.ReactElement => {
  const data: QueryData = useStaticQuery(graphql`
    {
      allSanityProjectField(sort: { fields: orderRank }) {
        nodes {
          name
          id
        }
      }
      allSanityProject(sort: { fields: orderRank }) {
        nodes {
          ...Project
        }
      }
    }
  `);

  const allProjectsFields = data.allSanityProjectField.nodes;
  const allProjects = data.allSanityProject.nodes;

  return (
    <section id="projects" className={styles.projectsSection}>
      <div className={styles.projectsContainer}>
        <h2>{title}</h2>
        <div className={styles.accordionContainer}>
          {allProjectsFields.map(field => (
            <ProjectFieldRow
              key={field.id}
              title={field.name}
              projects={allProjects.filter(project => project.field.id === field.id)}
              onViewGalleryClick={onViewGalleryClick}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
