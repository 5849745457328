import React from 'react';
import initialLogo from '../images/heroLogos/initialLogo.svg';
import finalLogo from '../images/heroLogos/finalLogo.svg';
import lightLogo from '../images/heroLogos/logo-light.svg';
import becomesLogo from '../images/heroLogos/logo-becomes.svg';
import spaceLogo from '../images/heroLogos/logo-space.svg';

import 'animate.css';

import scrollImage from '../images/scrollImage.svg';
import Mouse from '../images/Mouse.svg';

import * as styles from './Hero.module.scss';

const Hero = (): React.ReactElement => {
  return (
    <section id="hero" className={styles.heroContainer}>
      <div className={styles.mainContent}>
        <div className={styles.logoContainer}>
          <div className={styles.topLogoContainer}>
            <div className={styles.initialLogo}>
              <img src={initialLogo} alt="" className={styles.logo} />
            </div>
            <div className={styles.finalLogo + ' ' + styles.fadeIn}>
              <img src={finalLogo} alt="" className={styles.logo} />
            </div>
          </div>
          <div className={styles.bottomLogoContainer}>
            <div className={styles.lightLogo}>
              <img
                src={lightLogo}
                alt=""
                className={styles.logo + ' animate__animated animate__fadeInUp animate__delay-1s'}
              />
            </div>
            <div className={styles.becomesLogo}>
              <img
                src={becomesLogo}
                alt=""
                className={styles.logo + ' animate__animated animate__fadeInUp animate__delay-2s'}
              />
            </div>
            <div className={styles.spaceLogo}>
              <img
                src={spaceLogo}
                alt=""
                className={styles.logo + ' animate__animated animate__fadeInUp animate__delay-3s'}
              />
            </div>
          </div>
        </div>
        <div className={styles.scrollContainer}>
          <div className={styles.scroll + ' ' + styles.fadeIn}>
            <img className={styles.mouse} src={Mouse} alt="" />
            <img className={styles.scrollText} src={scrollImage} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
