// extracted by mini-css-extract-plugin
export var address = "ContactsSection-module--address--1vqgj";
export var addressAndContacts = "ContactsSection-module--addressAndContacts---XJg0";
export var contact = "ContactsSection-module--contact--Ez3ox";
export var contactIcon = "ContactsSection-module--contactIcon--IM1Mr";
export var contactValue = "ContactsSection-module--contactValue--Ls-qU";
export var contactsAndIcons = "ContactsSection-module--contactsAndIcons--zAX-w";
export var contactsContainer = "ContactsSection-module--contactsContainer--dBy-Y";
export var contactsInfo = "ContactsSection-module--contactsInfo--mIiIS";
export var contentContainer = "ContactsSection-module--contentContainer--NXhSA";
export var formContainer = "ContactsSection-module--formContainer--kLIYt";
export var map = "ContactsSection-module--map--H0kYN";