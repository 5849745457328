// extracted by mini-css-extract-plugin
export var contactBlock = "DepartmentContactBlock-module--contactBlock--JKcQc";
export var contactEmail = "DepartmentContactBlock-module--contactEmail--w0nom";
export var contactInfo = "DepartmentContactBlock-module--contactInfo--rirzD";
export var contactName = "DepartmentContactBlock-module--contactName--oAHqi";
export var contactPhoneNumber = "DepartmentContactBlock-module--contactPhoneNumber--Lpxdw";
export var contactPosition = "DepartmentContactBlock-module--contactPosition--2cBQ3";
export var contactPostionAndName = "DepartmentContactBlock-module--contactPostionAndName--aPN7U";
export var contacts = "DepartmentContactBlock-module--contacts--1f44r";
export var divider = "DepartmentContactBlock-module--divider--YPQ3u";
export var mainTitle = "DepartmentContactBlock-module--mainTitle--IgF6U";