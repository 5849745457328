import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './DepartmentContactBlock.module.scss';

interface DepartmentContactBlockProps {
  className: string;
  title: string;
  position: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
}

const DepartmentContactBlock = ({
  className,
  title,
  position,
  name,
  phoneNumber,
  email,
}: DepartmentContactBlockProps): React.ReactElement => {
  const { i18n, t } = useTranslation();

  return (
    <div className={styles.contactBlock + ' ' + className}>
      <h3 className={styles.mainTitle}>{title}</h3>
      <div className={styles.divider}></div>
      <div className={styles.contactInfo}>
        <div className={styles.contactPostionAndName}>
          <div className={styles.contactPosition}>
            <p>{position}</p>
          </div>
          {name && (
            <div className={styles.contactName}>
              <p>{name}</p>
            </div>
          )}
        </div>
        <div className={styles.contacts}>
          <a className={styles.contactPhoneNumber} href={'tel:' + phoneNumber}>
            {phoneNumber}
          </a>
          <a
            className={styles.contactEmail}
            href={'mailto:' + email}
            target="_blank"
            rel="noreferrer"
          >
            {email}
          </a>
        </div>
      </div>
    </div>
  );
};

export default DepartmentContactBlock;
