import React from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './Divider.module.scss';

interface DividerProps {
  className?: string;
}

const Divider = ({ className }: DividerProps): React.ReactElement => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.divider}></div>
    </div>
  );
};

export default Divider;
