// extracted by mini-css-extract-plugin
export var backgroundWord = "PhotoReportsSection-module--backgroundWord--GLXxl";
export var backgroundWordContainer = "PhotoReportsSection-module--backgroundWordContainer--60gxA";
export var cardCarousel = "PhotoReportsSection-module--cardCarousel--fnv3A";
export var carouselContainer = "PhotoReportsSection-module--carouselContainer--MxJBt";
export var contentContainer = "PhotoReportsSection-module--contentContainer--8yg3g";
export var divider = "PhotoReportsSection-module--divider--ElCgx";
export var dividerLight = "PhotoReportsSection-module--dividerLight--nyWRz";
export var highlightedPhrase = "PhotoReportsSection-module--highlightedPhrase---Q7kY";
export var photoshootSection = "PhotoReportsSection-module--photoshootSection--44+LA";
export var rightSideLight = "PhotoReportsSection-module--rightSideLight--kR6X0";