import React from 'react';
import { graphql } from 'gatsby';

import '../../fragments.ts';

import * as styles from './DepartmentsContactsSection.module.scss';
import DepartmentContactBlock from '../DepartmentContactBlock';

export const DepartmentsContactsSectionFragment = graphql`
  fragment DepartmentsContactsSection on SanityDepartmentsContactsSection {
    title
    departmentsContacts {
      title
      position
      name
      phoneNumber
      email
    }
  }
`;

export type DepartmentsContactsSectionProps = {
  title: string;
  departmentsContacts: Array<{
    title: string;
    position: string;
    name: string;
    phoneNumber: string;
    email: string;
  }>;
};

const DepartmentsContactsSection = ({
  title,
  departmentsContacts,
}: DepartmentsContactsSectionProps): React.ReactElement => {
  return (
    <section id="contacts" className={styles.contentContainer}>
      <div className={styles.departmentsContactsContainer}>
        <h2>{title}</h2>
        <div className={styles.contactsBlock}>
          {departmentsContacts.map((contact, i) => (
            <DepartmentContactBlock
              key={i}
              title={contact.title}
              position={contact.position}
              name={contact.name}
              phoneNumber={contact.phoneNumber}
              email={contact.email}
              className={styles.contact}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default DepartmentsContactsSection;
