import * as Sentry from '@sentry/react';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';

import '../../fragments.ts';
import { replaceNewLinesWithBr } from '../../utils/sanity';

import { FaEnvelope, FaFax, FaPhone } from 'react-icons/fa';
import Form from '../Form';
import * as styles from './ContactsSection.module.scss';

import mapMarkerImage from '../../images/map-marker.png';

const MAP_COORDS = [38.7154356, -9.3302814] as const;
const MAP_ZOOM = 16;

export const ContactsSectionFragment = graphql`
  fragment ContactsSection on SanityContactsSection {
    formTitle
    address
    phoneNumber
    faxNumber
    email
  }
`;

export type ContactsSectionProps = {
  formTitle: string;
  address: string;
  phoneNumber: string;
  faxNumber?: string;
  email: string;
};

const ContactsSection = ({
  formTitle,
  address,
  phoneNumber,
  faxNumber,
  email,
}: ContactsSectionProps): React.ReactElement => {
  useEffect(() => {
    const startGoogleMapsCheckTime = Date.now();
    const checkGoogleMapsInterval = setInterval(() => {
      if (window.google && window.google.maps) {
        clearInterval(checkGoogleMapsInterval);
        const mapOptions = {
          disableDefaultUI: true,
          keyboardShortcuts: false,
          zoom: MAP_ZOOM,
          backgroundColor: '#212121',
          center: new window.google.maps.LatLng(...MAP_COORDS),
          styles: [
            {
              featureType: 'all' as const,
              elementType: 'labels.text.fill' as const,
              stylers: [{ saturation: 36 }, { color: '#000000' }, { lightness: 40 }],
            },
            {
              featureType: 'all' as const,
              elementType: 'labels.text.stroke' as const,
              stylers: [{ visibility: 'on' }, { color: '#000000' }, { lightness: 16 }],
            },
            {
              featureType: 'all' as const,
              elementType: 'labels.icon' as const,
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'administrative' as const,
              elementType: 'geometry.fill' as const,
              stylers: [{ color: '#000000' }, { lightness: 13 }],
            },
            {
              featureType: 'administrative' as const,
              elementType: 'geometry.stroke' as const,
              stylers: [{ color: '#000000' }, { lightness: 10 }, { weight: 1.2 }],
            },
            {
              featureType: 'landscape' as const,
              elementType: 'geometry' as const,
              stylers: [{ color: '#000000' }, { lightness: 13 }],
            },
            {
              featureType: 'poi' as const,
              elementType: 'geometry' as const,
              stylers: [{ color: '#000000' }, { lightness: 14 }],
            },
            {
              featureType: 'road.highway' as const,
              elementType: 'geometry.fill' as const,
              stylers: [{ color: '#000000' }, { lightness: 20 }],
            },
            {
              featureType: 'road.highway' as const,
              elementType: 'geometry.stroke' as const,
              stylers: [{ color: '#000000' }, { lightness: 30 }, { weight: 0.2 }],
            },
            {
              featureType: 'road.arterial' as const,
              elementType: 'geometry' as const,
              stylers: [{ color: '#000000' }, { lightness: 19 }],
            },
            {
              featureType: 'road.local' as const,
              elementType: 'geometry' as const,
              stylers: [{ color: '#000000' }, { lightness: 16 }],
            },
            {
              featureType: 'transit' as const,
              elementType: 'geometry' as const,
              stylers: [{ color: '#000000' }, { lightness: 24 }],
            },
            {
              featureType: 'water' as const,
              elementType: 'geometry' as const,
              stylers: [{ color: '#000000' }, { lightness: 10 }],
            },
          ],
        };

        const mapElement = document.getElementById('map');

        const map = new window.google.maps.Map(mapElement!, mapOptions);

        const marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(...MAP_COORDS),
          map: map,
          title: 'LightLab',
          icon: {
            url: mapMarkerImage,
            scaledSize: new window.google.maps.Size(32, 44),
          },
        });

        marker.addListener('click', () => {
          window.open('https://www.google.com/maps/search/?api=1&query=' + encodeURI(address));
        });
      } else if (Date.now() - startGoogleMapsCheckTime > 3000) {
        clearInterval(checkGoogleMapsInterval);
        Sentry.captureException('Gave up on loading map after 3 seconds');
      }
    }, 100);
  }, []);

  return (
    <section id="contactForm" className={styles.contentContainer}>
      <div className={styles.contactsContainer}>
        <div className={styles.contactsInfo}>
          <div id="map" className={styles.map} />
          <div className={styles.addressAndContacts}>
            <a
              className={styles.address}
              href={'https://www.google.com/maps/search/?api=1&query=' + encodeURI(address)}
              target="_blank"
              rel="noreferrer"
            >
              {replaceNewLinesWithBr(address)}
            </a>
            <div className={styles.contactsAndIcons}>
              <div className={styles.contact}>
                <FaPhone className={styles.contactIcon} />
                <a className={styles.contactValue} href={'tel:' + phoneNumber}>
                  {phoneNumber}
                </a>
              </div>
              {faxNumber && (
                <div className={styles.contact}>
                  <FaFax className={styles.contactIcon} />
                  <p className={styles.contactValue}>{faxNumber}</p>
                </div>
              )}
              <div className={styles.contact}>
                <FaEnvelope className={styles.contactIcon} />
                <a
                  className={styles.contactValue}
                  href={'mailto:' + email}
                  target="_blank"
                  rel="noreferrer"
                >
                  {email}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formContainer}>
          <h2>{formTitle}</h2>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default ContactsSection;
