import React, { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageGallery from 'react-image-gallery';
import { Portal } from '@material-ui/core';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import '../fragments.ts';

import withI18n from '../utils/hocs';
import { Project, Seo } from '../fragments';
import AboutUsSection, { AboutUsSectionProps } from '../components/sections/AboutUsSection';
import OurSpaceSection, { OurSpaceSectionProps } from '../components/sections/OurSpaceSection';
import PartnersSection, { PartnersSectionProps } from '../components/sections/PartnersSection';
import ProjectsSection, { ProjectsSectionProps } from '../components/sections/ProjectsSection';
import PhotoReportsSection, {
  PhotoReportsSectionProps,
} from '../components/sections/PhotoReportsSection';
import DepartmentsContactsSection, {
  DepartmentsContactsSectionProps,
} from '../components/sections/DepartmentsContactsSection';
import ContactsSection, { ContactsSectionProps } from '../components/sections/ContactsSection';
import Hero from '../components/Hero';
import PageSEO from '../components/PageSEO';
import Divider from '../components/sections/Divider';

import '../styles/main.scss';
import '../styles/libraryStyles.css';
import * as styles from './index.module.scss';
import { shuffle } from '../utils/nodash';

export const query = graphql`
  {
    sanityWebsite {
      seo {
        ...SeoWebsite
      }
      aboutUsSection {
        ...AboutUsSection
      }
      photoReportsSection {
        ...PhotoReportsSection
      }
      projectsSection {
        ...ProjectsSection
      }
      partnersSection {
        ...PartnersSection
      }
      ourSpaceSection {
        ...OurSpaceSection
      }
      departmentsContactsSection {
        ...DepartmentsContactsSection
      }
      contactsSection {
        ...ContactsSection
      }
    }
    allSanityProject {
      nodes {
        ...Project
      }
    }
  }
`;

interface IndexPageProps {
  data: {
    sanityWebsite: {
      aboutUsSection: AboutUsSectionProps;
      photoReportsSection: PhotoReportsSectionProps;
      projectsSection: ProjectsSectionProps;
      partnersSection: PartnersSectionProps;
      ourSpaceSection: OurSpaceSectionProps;
      departmentsContactsSection: DepartmentsContactsSectionProps;
      contactsSection: ContactsSectionProps;
      seo: Seo;
    };
    allSanityProject: {
      nodes: Array<Project>;
    };
  };
}
const IndexPage = ({ data }: IndexPageProps): React.ReactElement => {
  const {
    aboutUsSection,
    photoReportsSection,
    projectsSection,
    partnersSection,
    ourSpaceSection,
    departmentsContactsSection,
    contactsSection,
    seo,
  } = data.sanityWebsite;

  const [activeProjectId, setActiveProjectId] = useState<string | null>(null);
  const allProjects = data.allSanityProject.nodes;
  const shuffledProjects = useRef(
    shuffle(allProjects.map(project => ({ ...project, photos: shuffle(project.photos) }))),
  );
  const activeProject =
    shuffledProjects.current.find(project => project._id === activeProjectId) || null;
  useEffect(() => {
    AOS.init({
      once: true,
    });
    setTimeout(() => {
      AOS.refresh();
    }, 500);
  }, []);

  return (
    <Layout>
      <PageSEO seo={seo} />
      <Hero />
      <AboutUsSection {...aboutUsSection} />
      <Divider className={styles.dividerNoMobile} />
      <PhotoReportsSection
        {...{
          ...photoReportsSection,
          // Pass the shuffled projects instead of the non-shuffled ones
          // but only those that the photoReportsSection asked for.
          projects: shuffledProjects.current.filter(project =>
            photoReportsSection.projects.map(p => p._id).includes(project._id),
          ),
        }}
        onViewGalleryClick={setActiveProjectId}
      />
      <ProjectsSection {...projectsSection} onViewGalleryClick={setActiveProjectId} />
      <Divider className={styles.dividerNoMobile} />
      <PartnersSection {...partnersSection} />
      <OurSpaceSection {...ourSpaceSection} />
      <Divider className={styles.dividerNoMobile} />
      <DepartmentsContactsSection {...departmentsContactsSection} />
      <ContactsSection {...contactsSection} />
      {activeProjectId !== null && (
        <Portal>
          <div
            className={styles.overlay}
            onClick={() => {
              setActiveProjectId(null);
            }}
          >
            <div className={styles.imageGalleryContainer} onClick={e => e.stopPropagation()}>
              <ImageGallery
                items={activeProject!.photos.map(image => ({
                  original: image.asset.url + '?w=1600&h=960&fit=max',
                  fullscreen: image.asset.url + '?w=1920&h=1000&fit=max',
                  thumbnail: image.asset.url + '?w=200&h=120&fit=min',
                  // originalAlt: image.asset.originalFilename,
                }))}
                showBullets
                additionalClass={styles.imageGallery}
              />
            </div>
            <div className={styles.projectInfo}>
              <div className={styles.nameAndLocation}>
                {activeProject!.name + ' | ' + activeProject!.location}
              </div>
              {activeProject!.responsibleCompanies.length >= 0 && (
                <div className={styles.responsibleCompanies}>
                  {activeProject!.responsibleCompanies
                    .map(
                      responsibleCompany =>
                        responsibleCompany.area + ': ' + responsibleCompany.name,
                    )
                    .join(' | ')}
                </div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </Layout>
  );
};
export default withI18n(IndexPage);
